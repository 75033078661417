html {
  scroll-behavior: smooth;
  background-color: #0f213e;
}

body {
  margin: 0;
  padding: 0;
  background-color: #0f213e;
  background: linear-gradient(to bottom, rgba(15, 33, 62, 0) 35%, rgba(15, 33, 62, 1)) 10%;
  background-image: url('/images/main_bg.jpg');
  background-attachment: fixed;
  background-size: cover;
  color: #0f213e;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

a,
a:visited {
  color: #0063bb;
  text-decoration: none;
}

h6 {
  font-size: 20px !important;
}

h5 {
  font-size: 22px !important;
}
